import Executive from '../rmi/Executive'

//import { User, Role } from '../api2nest/src/entities'
export { User, Role } from '../entities'

export default class FastifyTestExecutive extends Executive {
	// TODO: eventually ALL executives should be migrated to the nest server and this will be the default endpoint
	public endpoint = '/api2fastify/RMI'

	initServer() {
	}

	async test(param: string): Promise<string> {
		return 'echo2:' + param
	}
}