import { Entity, EntityRepositoryType, ManyToOne, PrimaryKey, Property } from '@mikro-orm/postgresql'
//import { Dto } from '../rmi/entry-decorators'
import { EntityRepository } from '@mikro-orm/postgresql'

// TODO: rename file, we dont want to use "Entries" anymore,

class RoleRepository extends EntityRepository<Role> {}

@Entity({ repository: () => RoleRepository })
export class Role {
	[EntityRepositoryType]?: RoleRepository

	@PrimaryKey()
	id: bigint

	@Property()
	name: string

	constructor(name: string) {
		this.name = name
	}
}

class UserRepository extends EntityRepository<User> {}

//@Dto()
@Entity({ repository: () => UserRepository })
export class User {
	[EntityRepositoryType]?: UserRepository

	@PrimaryKey()
	id: bigint

	@Property()
	name: string

	@ManyToOne()
	role: Role

	constructor(name: string) {
		console.log('User.constructor')
		this.name = name
	}
}
