<template>
	<div>
		Nest Test: {{ result }}
	</div>
</template>

<script lang="ts">
//import Api2NestTestExecutive, { User } from '../../../../api2nest/src/rmi/Api2NestTestExecutive'
//import { User, Role } from '../../../../api2nest/src/entities'
import FastifyTestExecutive from '../../../../api2fastify/src/modules/test/FastifyTestExecutive'

export default {
	data: () => ({
		result: null,
	}),
	async mounted() {
		/*
		const u = new User('name')
		console.log('u', u)
		const x = new Api2NestTestExecutive({ clientId: 'C1' }, this)
		this.result = await x.test('hello')
		*/

		const cc = new FastifyTestExecutive(this)
		this.result = await cc.test('3')
	},
}
</script>